import { useMutation, useQuery } from "@tanstack/react-query";
import {
  backgroundRefreshToken,
  getMetaxInfo,
  passwordReset,
  signIn,
  signOut,
  updateFirstBookStoreInfo,
} from "api/authApi";
import { getUserInfo } from "api/userApi";
import queryKey from "common/constants/queryKey";
import { useModal, useNavigation, useUser } from "common/hooks";
import usePreMutation from "common/hooks/usePreMutation";
import { deleteCookie, getCookie } from "common/utils/cookieUtils";
import { axiosInstance } from "config/axiosConfig";
import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import type { APIErrorResponse, APIResponseData } from "types/api";
import type { URLPaths } from "types/urls";
import type { UserInfo, UserResponse } from "types/user";

export const useGetMetaxInfoMutation = () => {
  const mutation = useMutation(getMetaxInfo, { useErrorBoundary: false });
  return { ...mutation };
};

export const useSignInQuery = () => {
  const [resultCd, setResultCd] = useState(Number);
  const [resData, setResData] = useState<Record<string, any>>();
  const [errorMessage, setErrorMessage] = useState("");
  const { navigate } = useNavigation();
  const { search } = useLocation();
  const { setUser } = useUser();

  const mutation = useMutation(signIn, {
    useErrorBoundary: false,
    onError: (error: APIErrorResponse | any) => {
      if (error.code === "ERR_NETWORK") {
        alert("서버 오류로 서비스를 사용할 수 없습니다.");
      } else {
        if (error.status === 500) {
          alert("서버 오류입니다.");
        } else {
          alert(error?.data?.resultMsg);
        }
      }
    },

    onSuccess: (res: APIResponseData<UserResponse>) => {
      if (res.resultCd === 201) {
        // 최초 로그인 여부 확인
        if (res.data) {
          setResultCd(res.resultCd);
          setResData(res.data);
        }
      } else {
        // 정상 로그인
        if (res.data) {
          const {
            MOD_SECRET_KEY,
            shipFreeAmt,
            cont_gbnm,
            vbankLimitAmt,
            USER_ID,
            NICK_NAME,
            jptax_no,
            shipAmt,
            banphan_amt,
            misu_amt,
            juing_amt,
            jpcust_nm,
            cardLimitAmt,
            han_amt,
            accounts,
            cont_gb,
            mid,
            merchantKey,
            meclass_gb,
            meclass_user_id,
            mallAdmSignInUrl,
            initSecretKeyYn,
            cust_gb,
            besong_gb,
          } = res.data;

          // alert(`${NICK_NAME}님, 환영합니다.`);

          setUser({
            userName: NICK_NAME,
            modSecretKey: MOD_SECRET_KEY,
            banphan_amt,
            han_amt,
            misu_amt,
            juing_amt,
            cont_gb,
            cont_gbnm,
            branchCode: USER_ID,
            headCode: jptax_no,
            shipFreeAmt,
            vbankLimitAmt,
            shipAmt,
            cardLimitAmt,
            accounts,
            jpcust_nm,
            mid,
            merchantKey,
            meclass_gb,
            meclass_user_id,
            mallAdmSignInUrl,
            initSecretKeyYn,
            cust_gb,
            besong_gb,
          });
          const newAccessToken = getCookie("scm_store_at");
          axiosInstance.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${newAccessToken}`;
          let redirectURL: string;
          if (res.resultCd === 202) {
            redirectURL = `/my-page`;
          } else {
            redirectURL =
              search.substring(10).length > 0 ? search.substring(10) : "/";
          }
          navigate(redirectURL as URLPaths);
        }
      }
    },
  });

  const handleSignIn = useCallback(
    (corpNo: number, metaxNo: string, secretKey: string) => {
      setErrorMessage("");

      if (!(metaxNo && corpNo && secretKey)) {
        setErrorMessage("아이디와 비밀번호를 입력해주세요.");
        return;
      } else {
        mutation.mutateAsync({
          corpNo: `${corpNo}`,
          userId: metaxNo,
          secretKey,
        });
      }
    },
    [],
  );

  return {
    ...mutation,
    errorMessage,
    resultCd,
    resData,
    handleSignIn,
  };
};

export const useSignOutQuery = () => {
  const { preMutation } = usePreMutation();
  const { mutateAsync } = useMutation(signOut, {
    useErrorBoundary: false,
    onSuccess: () => {
      deleteCookie("scm_store_at");
      location.href = "/sign-in";
    },
    onError: (error: APIErrorResponse) => {
      alert(error.data.resultMsg);
      deleteCookie("scm_store_at");
      location.href = "/sign-in";
    },
  });
  const querySignOut = async () => {
    await preMutation();
    await mutateAsync();
  };
  return { querySignOut };
};

//TODO: api 함수 signIn에서 changePassword로 변경
export const useUserValidateQuery = () => {
  const [isUserValid, setIsUserValid] = useState(false);
  const [userValidMessage, setUserValidMessage] = useState("");

  const { mutateAsync: validateUser } = useMutation(signIn, {
    useErrorBoundary: false,
    onError: (error: APIErrorResponse) => {
      setUserValidMessage(error.data.resultMsg);
    },
    onSuccess: (res: APIResponseData<UserInfo>) => {
      if (res.resultCd === 200) {
        setIsUserValid(true);
        setUserValidMessage("비밀번호가 일치합니다.");
      } else {
        setUserValidMessage("현재 비밀번호를 맞게 입력해주세요.");
      }
    },
  });

  return { isUserValid, userValidMessage, validateUser };
};

export const useBackgroundRefreshTokenQuery = () => {
  const { setUser } = useUser();

  const { refetch } = useQuery([queryKey.USER], getUserInfo, {
    enabled: false,
    onSuccess: (res: APIResponseData<UserResponse>) => {
      if (res.data) {
        const {
          MOD_SECRET_KEY,
          shipFreeAmt,
          cont_gbnm,
          vbankLimitAmt,
          USER_ID,
          NICK_NAME,
          jptax_no,
          shipAmt,
          banphan_amt,
          misu_amt,
          juing_amt,
          jpcust_nm,
          cardLimitAmt,
          han_amt,
          accounts,
          cont_gb,
          mid,
          merchantKey,
          meclass_gb,
          meclass_user_id,
          mallAdmSignInUrl,
          initSecretKeyYn,
          cust_gb,
          besong_gb,
        } = res.data;
        setUser({
          userName: NICK_NAME,
          modSecretKey: MOD_SECRET_KEY,
          banphan_amt,
          han_amt,
          misu_amt,
          juing_amt,
          cont_gb,
          cont_gbnm,
          branchCode: USER_ID,
          headCode: jptax_no,
          shipFreeAmt,
          vbankLimitAmt,
          shipAmt,
          cardLimitAmt,
          accounts,
          jpcust_nm,
          mid,
          merchantKey,
          meclass_gb,
          meclass_user_id,
          mallAdmSignInUrl,
          initSecretKeyYn,
          cust_gb,
          besong_gb,
        });
      }
    },
    useErrorBoundary: false,
  });

  const mutation = useMutation(backgroundRefreshToken, {
    onSuccess: (res: APIResponseData<any>) => {
      if (res.resultCd === 999) {
        deleteCookie("scm_store_at");
        deleteCookie("scm_store_rt");

        location.href = "/sign-in";
      }

      refetch();
    },
  });
  return { ...mutation };
};

export const useBookStoreInfoUpdateQuery = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const { modal } = useModal();

  const mutation = useMutation(updateFirstBookStoreInfo, {
    useErrorBoundary: false,
    onError: (error: APIErrorResponse | any) => {
      if (error.code === "ERR_NETWORK") {
        setErrorMessage("서버 오류로 서비스를 사용할 수 없습니다.");
      } else {
        if (error.status === 500) {
          setErrorMessage("서버 오류입니다.");
        } else {
          setErrorMessage(error?.data?.resultMsg);
        }
      }
    },

    onSuccess: (res: APIResponseData<UserResponse>) => {
      // 정상 로그인
      alert(
        "정보가 정상적으로 저장되었습니다.\n" +
          "변경된 비밀번호로 로그인해주세요.\n\n" +
          "※ 주문하신 도서(*직배주문 제외)는 서점 정보의 주소를 기준으로 배송됩니다. " +
          "로그인 후 [거래관리]>[서점정보] 메뉴에서 배송지 주소를 확인해주시기 바랍니다.",
      );
    },
  });

  const handleSaveInfo = useCallback(
    (bookStoreInfo: Record<string, unknown>) => {
      setErrorMessage("");
      if (!bookStoreInfo) {
        setErrorMessage("정보를 입력해주세요");
        return;
      } else {
        if (
          window.confirm(
            "해당 정보는 조합과의 거래 정보 관리 및 주문배송 알림 수신에 필요한 정보입니다.\n작성 오류로 인하여 불이익이 발생할 수 있으니 정확한 정보를 기입해주시기 바랍니다.",
          )
        ) {
          mutation.mutateAsync({
            ...bookStoreInfo,
          });
          modal.close();
        } else {
          alert("취소되었습니다.");
        }
      }
    },
    [],
  );

  return {
    ...mutation,
    errorMessage,
    handleSaveInfo,
  };
};

export const usePasswordResetQuery = () => {
  const [errorMessage, setErrorMessage] = useState("");

  const mutation = useMutation(passwordReset, {
    useErrorBoundary: false,
    onError: (error: APIErrorResponse | any) => {
      if (error.code === "ERR_NETWORK") {
        alert("서버 오류로 서비스를 사용할 수 없습니다.");
      } else {
        if (error.status === 500) {
          alert("서버 오류입니다.");
        } else {
          alert(error?.data?.resultMsg);
        }
      }
    },
    onSuccess: (res: APIResponseData<UserResponse>) => {
      // 정상 로그인
      alert(res.resultMsg);
    },
  });

  const handlePasswordReset = useCallback((corpNo: number, metaxNo: string) => {
    setErrorMessage("");
    if (!(metaxNo && corpNo)) {
      alert("사업자번호를 입력 후 지점을 선택해주세요.");
      return;
    } else {
      mutation.mutateAsync({
        corpNo: `${corpNo}`,
        userId: metaxNo,
      });
    }
  }, []);

  return {
    ...mutation,
    handlePasswordReset,
  };
};

// 로그인 API | 개선중
export const useSignInMutation = () => {
  const { mutate: handleSignInMutate } = useMutation({
    mutationFn: signIn,
  });

  return { handleSignInMutate };
};

// 서점정보 조회 API | 개선중
export const useGetMetaxInfoMutationNew = () => {
  const { mutate: handleGetMetaxInfoMutate } = useMutation({
    mutationFn: getMetaxInfo,
    useErrorBoundary: false,
  });

  return { handleGetMetaxInfoMutate };
};
