export async function delay(ms: number) {
  return new Promise((resolve) =>
    setTimeout(() => {
      resolve(ms);
    }, ms),
  );
}

export const formatNumberWithComma = (x: number) => {
  if (isNaN(x)) {
    x = 0;
  }
  let returnValue = x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0;
  if (returnValue === "NaN") {
    returnValue = "0";
  }
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0;
};

export const parseNumberWithComma = (x: string) => {
  return Number(x.replace(",", "")) ?? 0;
};

export const generateRandomString = (length: number) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};
