import LoadingSpinner from "common/components/LoadingSpinner";
import PageContainer from "common/components/PageContainer";
import SignedInContainer from "common/components/PageContainer/SignedInContainer";
import SignedOutContainer from "common/components/PageContainer/SignedOutContainer";
import WheneverContainer from "common/components/PageContainer/WheneverContainerProps";
import { URLs } from "common/constants";
import { initialMenuState } from "common/constants/initialMenuState";
import React, {
  lazy,
  LazyExoticComponent,
  MemoExoticComponent,
  Suspense,
} from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import type { RouteCondition, URLPaths } from "types/urls";

interface RouteType {
  path: URLPaths;
  component:
    | LazyExoticComponent<() => JSX.Element>
    | LazyExoticComponent<MemoExoticComponent<() => JSX.Element>>;
  requireHeader: boolean;
  routeCondition: RouteCondition;
  cssId: string;
  title: string;
  description?: () => JSX.Element;
}

// 코드 스플리팅을 위한 lazy import
const Main = lazy(() => import("pages/Main"));
const SignIn = lazy(() => import("pages/SignIn"));
const SignInNew = lazy(() => import("pages/SignIn/SignInNew"));
const MyPage = lazy(() => import("pages/MyPage"));
const TotalSearch = lazy(() => import("pages/Orders/TotalSearch"));
const InvalidApproach = lazy(() => import("pages/InvalidApproach"));
const FileOrder = lazy(() => import("pages/Orders/FileOrder"));
const RecommendedOrder = lazy(() => import("pages/Orders/RecommendedOrder"));
const SearchOrder = lazy(() => import("pages/Orders/SearchOrder"));
const NewBookOrder = lazy(() => import("pages/Orders/NewBookOrder"));
const BestBookOrder = lazy(() => import("pages/Orders/BestBookOrder"));
const DirectDeliveryOrderSearch = lazy(
  () => import("pages/Orders/DirectDeliveryOrderSearch"),
);
const DirectDeliveryOrderSearchNew = lazy(
  () => import("pages/Orders/DirectDeliveryOrder/DirectDeliveryOrderSearchNew"),
);
const DirectDeliveryOrderFile = lazy(
  () => import("pages/Orders/DirectDeliveryOrderFile"),
);
const OrderInquiry = lazy(() => import("pages/Orders/OrderInquiry"));
const CartView = lazy(() => import("pages/Orders/CartView"));
const MallCenter = lazy(() => import("pages/MallCenter"));
const DeliveryOrderStatus = lazy(
  () => import("pages/Delivery/DeliveryOrderStatus"),
);
const DeliveryDealStatus = lazy(
  () => import("pages/Delivery/DeliveryDealStatus"),
);
const DeliveryProgress = lazy(() => import("pages/Delivery/DeliveryProgress"));
const OrderHistoryView = lazy(() => import("pages/History/OrderHistoryView"));
const OrderHistoryByDailyView = lazy(
  () => import("pages/History/OrderHistoryByDailyView"),
);
const DirectDeliveryOrderHistoryView = lazy(
  () => import("pages/History/DirectDeliveryOrderHistoryView"),
);
const OutcomingHistoryView = lazy(
  () => import("pages/History/OutcomingHistoryView"),
);
const RefundHistoryView = lazy(() => import("pages/History/RefundHistoryView"));
const ReturnRequestView = lazy(() => import("pages/History/ReturnRequestView"));
const ReturnRequestForm = lazy(() => import("pages/History/ReturnRequestForm"));
const ReturnRequestFormForPG = lazy(
  () => import("pages/History/ReturnRequestFormForPG"),
);
const DealsLedgerView = lazy(() => import("pages/Deals/DealsLedgerView"));
const BooksLedgerView = lazy(() => import("pages/Deals/BooksLedgerView"));
const BookStoreInfo = lazy(() => import("pages/Deals/BookStoreInfo"));
const Notice = lazy(() => import("pages/Support/Notice"));
const NoticeDetail = lazy(() => import("pages/Support/NoticeDetail"));
const OneToOne = lazy(() => import("pages/Support/OneToOne"));
const OneToOneDetail = lazy(() => import("pages/Support/OneToOneDetail"));
const OneToOneInquiry = lazy(() => import("pages/Support/OneToOneInquiry"));
const ManageCustomer = lazy(() => import("pages/Support/ManageCustomer"));
const ManageIncome = lazy(() => import("pages/Support/ManageIncome"));
const ReturnRequire = lazy(() => import("pages/Support/ReturnRequire"));
const PaymentSuccess = lazy(() => import("pages/Orders/PaymentSuccess"));
const PaymentFailure = lazy(() => import("pages/Orders/PaymentFailure"));

export const routes: RouteType[] = [
  {
    path: URLs.Main,
    component: Main,
    requireHeader: true,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "main-page",
    title: "한국출판협동조합 서점전용공간",
  },
  {
    path: URLs.SignIn,
    component: SignIn,
    requireHeader: false,
    routeCondition: "MUST_BE_SIGNED_OUT",
    cssId: "sign-in-page",
    title: "로그인",
  },
  {
    path: URLs.SignInNew,
    component: SignInNew,
    requireHeader: false,
    routeCondition: "MUST_BE_SIGNED_OUT",
    cssId: "sign-in-page-new",
    title: "로그인",
  },
  {
    path: URLs.MyPage,
    component: MyPage,
    requireHeader: true,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "my-page",
    title: "마이페이지",
  },
  {
    path: URLs.TotalSearch,
    component: TotalSearch,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "total-search",
    title: "통합검색",
  },
  {
    path: URLs.FileOrder,
    component: FileOrder,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "file-order",
    title: "파일등록주문",
  },
  {
    path: URLs.OrderInquiry,
    component: OrderInquiry,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "order-inquiry",
    title: "바로주문",
  },
  {
    path: URLs.RecommendedOrder,
    component: RecommendedOrder,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "recommended-order",
    title: "추천도서주문",
  },
  {
    path: URLs.SearchOrder,
    component: SearchOrder,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "search-order",
    title: "도서검색주문",
  },
  {
    path: URLs.NewBookOrder,
    component: NewBookOrder,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "new-book-order",
    title: "신간목록주문",
  },
  {
    path: URLs.BestBookOrder,
    component: BestBookOrder,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "best-book-order",
    title: "베스트목록주문",
  },
  {
    path: URLs.DirectDeliveryOrderSearch,
    component: DirectDeliveryOrderSearch,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "direct-delivery-order-search",
    title: "직배주문",
  },
  {
    path: URLs.DirectDeliveryOrderSearchNew,
    component: DirectDeliveryOrderSearchNew,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "direct-delivery-order-search-new",
    title: "직배주문",
  },
  {
    path: URLs.DirectDeliveryOrderFile,
    component: DirectDeliveryOrderFile,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "direct-delivery-order-file",
    title: "직배주문(엑셀주문)",
  },
  {
    path: URLs.CartView,
    component: CartView,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "cart-view",
    title: "장바구니",
  },
  {
    path: URLs.MallCenter,
    component: MallCenter,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "mall-center",
    title: "모두같이센터",
  },
  {
    path: URLs.MallCenter,
    component: MallCenter,
    requireHeader: true,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "my-page",
    title: "모두몰센터",
  },
  {
    path: URLs.DeliveryOrderStatus,
    component: DeliveryOrderStatus,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "delivery-order-status",
    title: "납품주문내역",
  },
  {
    path: URLs.DeliveryDealStatus,
    component: DeliveryDealStatus,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "delivery-deal-status",
    title: "납품거래내역",
  },
  {
    path: URLs.DeliveryProgress,
    component: DeliveryProgress,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "delivery-progress",
    title: "납품진행조회",
  },
  {
    path: URLs.OrderHistoryView,
    component: OrderHistoryView,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "order-history-view",
    title: "주문내역조회",
  },
  {
    path: URLs.OrderHistoryByDailyView,
    component: OrderHistoryByDailyView,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "order-history-by-daily-view",
    title: "일별주문내역조회",
  },
  {
    path: URLs.DirectDeliveryOrderHistoryView,
    component: DirectDeliveryOrderHistoryView,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "direct-delivery-order-history-view",
    title: "직배주문내역조회",
  },

  {
    path: URLs.OutcomingHistoryView,
    component: OutcomingHistoryView,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "outcoming-history-view",
    title: "출고내역조회",
  },
  {
    path: URLs.RefundHistoryView,
    component: RefundHistoryView,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "refund-history-view",
    title: "반품내역조회",
  },
  {
    path: URLs.ReturnRequestView,
    component: ReturnRequestView,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "return-request-view",
    title: "온라인반품 조회(신청)",
  },
  {
    path: URLs.ReturnRequestForm,
    component: ReturnRequestForm,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "return-request-form",
    title: "온라인반품 가능도서조회",
  },
  {
    path: URLs.ReturnRequestFormForPG,
    component: ReturnRequestFormForPG,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "return-request-form-for-pg",
    title: "온라인반품 가능도서조회",
  },
  {
    path: URLs.BooksLedgerView,
    component: BooksLedgerView,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "books-ledger-view",
    title: "도서원장조회",
  },
  {
    path: URLs.DealsLedgerView,
    component: DealsLedgerView,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "deals-ledger-view",
    title: "거래원장조회",
  },
  {
    path: URLs.BookStoreInfo,
    component: BookStoreInfo,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "book-store-info",
    title: "서점정보",
  },
  {
    path: URLs.Notice,
    component: Notice,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "notice",
    title: "공지사항",
  },
  {
    path: URLs.NoticeDetail,
    component: NoticeDetail,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "notice-detail",
    title: "공지사항",
  },
  {
    path: URLs.OneToOne,
    component: OneToOne,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "one-to-one",
    title: "1:1 문의",
  },
  {
    path: URLs.OneToOneDetail,
    component: OneToOneDetail,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "one-to-one-detail",
    title: "1:1 문의",
  },
  {
    path: URLs.OneToOneInquiry,
    component: OneToOneInquiry,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "one-to-one-inquiry",
    title: "1:1 문의",
  },
  {
    path: URLs.ReturnRequire,
    component: ReturnRequire,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "return-require",
    title: "반품요청공지",
  },
  {
    path: URLs.ManageCustomer,
    component: ManageCustomer,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "manage-customer",
    title: "고객관리",
  },
  {
    path: URLs.ManageIncome,
    component: ManageIncome,
    requireHeader: true,
    routeCondition: "WHENEVER",
    cssId: "manage-income",
    title: "매출관리",
  },
  {
    path: URLs.PaymentSuccess,
    component: PaymentSuccess,
    requireHeader: false,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "payment-success",
    title: "",
  },
  {
    path: URLs.PaymentFailure,
    component: PaymentFailure,
    requireHeader: false,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "payment-failure",
    title: "",
  },
];

const PageRoutes = () => {
  return (
    <Router>
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          {routes.map((route, index) => {
            const {
              path,
              component: Component,
              routeCondition,
              cssId: id,
              title,
              description,
            } = route;
            let menuIdx = -1;
            initialMenuState.forEach((menu, idx) => {
              menu.childMenus.forEach((child) => {
                if (child.title === title) {
                  menuIdx = idx;
                }
              });
            });
            const parentMenu =
              menuIdx !== -1 ? initialMenuState[menuIdx].title : null;

            switch (routeCondition) {
              case "WHENEVER":
                return (
                  <Route
                    key={index}
                    path={path}
                    element={
                      <WheneverContainer>
                        <PageContainer
                          id={id}
                          title={title}
                          parentMenu={parentMenu}
                          description={description}
                        >
                          <Component />
                        </PageContainer>
                      </WheneverContainer>
                    }
                  />
                );
              case "MUST_BE_SIGNED_IN":
                return (
                  <Route
                    key={index}
                    path={path}
                    element={
                      <SignedInContainer>
                        <PageContainer
                          id={id}
                          title={title}
                          parentMenu={parentMenu}
                          description={description}
                        >
                          <Component />
                        </PageContainer>
                      </SignedInContainer>
                    }
                  />
                );
              case "MUST_BE_SIGNED_OUT":
                return (
                  <Route
                    key={index}
                    path={path}
                    element={
                      <SignedOutContainer>
                        <PageContainer
                          id={id}
                          title={title}
                          parentMenu={parentMenu}
                          description={description}
                        >
                          <Component />
                        </PageContainer>
                      </SignedOutContainer>
                    }
                  />
                );
            }
          })}
          <Route path="*" element={<InvalidApproach />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default PageRoutes;
